import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'
import { registerLog } from '@/libs/log'
import debounce from '@/drective/debounce'
import { OSS_HOST } from '@/config/index'
// import VueAwesomeSwiper from 'vue-awesome-swiper'

// 接口
import WmApi from './api'

// 微信
import promiseWx from '@/libs/promiseWx'

// 公用样式
import '@/scss/index.scss'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// vant ui
import registerVant from './libs/vant'

// Vconsole
import '@/libs/vconsole'

import bridge from './libs/bridge'

// setTitle
import { setDocumentTitle, getOS } from '@/utils'

// 全局组件 modal
import BaseModal from '@/components/BaseModal'

// 获取设备信息
getOS()

sync(store, router)

registerVant(Vue)
Vue.use(WmApi)
Vue.use(promiseWx)
Vue.use(debounce)
// Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.prototype.$setTitle = setDocumentTitle
Vue.prototype.$OSS_HOST = OSS_HOST
registerLog(Vue, store)

Vue.prototype.$bridge = bridge

Vue.component('BaseModal', BaseModal)

store.$apis = WmApi
Vue.prototype.baseUrl = '//wm-front-common.weimiaocaishang.com/wm-front-landing-page-mobile/assets/'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
